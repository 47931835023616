import styled from 'styled-components'

export const Option = styled.li`
  list-style-type: none;
`

export const PreviousAnswer = styled.tr`    
  background-color: ${(props: any) => props.isCorrect === true ? "#dff0d8" : "#f2dede"}  
`

export const ReadMore = styled.td`
  column-span: all;
  padding-left: 10px;
  font-size : 0.8em;
`