import * as PreviousQuestions from './PreviousQuestions'
import * as Style from './Style'

export interface Props {
  question: string
  answers: string[]  
  chosenAnswer: string
  answerQuestion : () => void  
  setChosenAnswer : (answer: string) => void
  previousQuestions: PreviousQuestions.Props
}

export function Composer(props: Props) {
  let answerCharCode = 65;
  const answers = props.answers.map(answer => {
    let answerChar = String.fromCharCode(answerCharCode)
    return (
      <Style.Option key={answerChar}>
        <input 
          type="radio" 
          name="answer" 
          value={answerChar} 
          checked={answerChar === props.chosenAnswer} 
          onChange={() => props.setChosenAnswer(answerChar)}/>{String.fromCharCode(answerCharCode++)}. {answer}
      </Style.Option>)
  })

  return (
  <div>
    <div>
    <span>{props.question}</span>   
    <ul>
      { answers }
    </ul>
    <button onClick={props.answerQuestion} autoFocus>Besvar</button>
    </div>
    <div>
      <PreviousQuestions.PreviousQuestions previousQuestions={props.previousQuestions.previousQuestions}/>
    </div>
  </div>)

}