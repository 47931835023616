import * as Game from '../SimpleGame'

export interface Props {
  question : string
  answerOptions : string[]
  chosenAnswer: string
  answerQuestion : () => void  
  setChosenAnswer: (answer: string) => void  
  previousQuestions: Game.PreviousQuestion[]
}

export function Composer(props: Props) {
  // const controller = useController()
  // const [count, setCount] =useState(1)

  // TODO: Should get game props directly in props
  const gameProps: Game.Props = {
    question : props.question,
    answers : props.answerOptions,
    chosenAnswer: props.chosenAnswer,
    answerQuestion : props.answerQuestion,
    setChosenAnswer : (answer: string) => props.setChosenAnswer(answer),
    previousQuestions : { previousQuestions : props.previousQuestions }
  }
  
  return (
    <div className='App'>
    <h1>Quizzing Cousins - gæt engang!</h1>
    <sub>Powered by <a href="https://mrlegacy.dk">Mr. Legacy</a> and <a href="https://wikidata.org">Wikidata</a></sub>
    <p></p>
    <Game.Composer {...gameProps}/>
  </div>)
}