import React from 'react'
import * as Style from './Style'

export interface PreviousQuestion {
  question: string
  answer: string
  correctAnswer: string
  readMore: string[]
  isCorrect: boolean
}

export interface Props {
  previousQuestions: PreviousQuestion[]
}

export function PreviousQuestions(props: Props) {

  const readMore = (q: PreviousQuestion) => {
    if (q.readMore.length === 0)
      return undefined

    return (<Style.PreviousAnswer {...q}>
      <Style.ReadMore colSpan={3}>
        {q.readMore.map(rm =>{
          return (<a key={rm} href={rm}>{rm + " "}</a>)}
        )}
      </Style.ReadMore>
    </Style.PreviousAnswer>)
  }

  return <table>
    <thead>
      <tr>
        <th>Tidligere spørgsmål</th>
        <th>Dit svar</th>
        <th>Korrekt svar</th>
      </tr>
    </thead>
    <tbody>
      {props.previousQuestions.map((q, index) => 
        <React.Fragment key={`a-${index}`}>
          <Style.PreviousAnswer {...q}>
            <td>{q.question}</td>
            <td>{q.answer}</td>
            <td>{q.correctAnswer}</td>
          </Style.PreviousAnswer>
          {readMore(q)}
        </React.Fragment>
      )}
    </tbody>
  </table>
}