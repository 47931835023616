import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Composer from './Composer'
import * as Repositories from '../../Infrastructure/Bootstrapping/Repositories'
import * as Domain from '../../Domain'
import * as Game from '../SimpleGame'

export function Controller() {
  // When more advanced games are introduced the logic will probably move to 
  // individual controllers for those games
  const [checkingAnswer, setCheckingAnswer] = useState(false)
  const [chosenAnswer, setChosenAnswer] = useState("")
  const [question, setQuestion] = useState<Domain.Question>()
  const [previousQuestions, setPreviousQuestions] = useState<Game.PreviousQuestion[]>([])

  const serverRoot =
    (process.env.NODE_ENV === "development")
      ? "https://localhost:49157/api"
      : "https://quizzingcousins.com/api"
  const repository = useMemo(() => new Repositories.Question(serverRoot), [serverRoot])

  const checkAnswer = useCallback(()=> {
    if (question == null || chosenAnswer === "" || checkingAnswer)
      return;

    setCheckingAnswer(true)

    repository.checkAnswer(question.id, chosenAnswer)
    .then(answer => {
      const newAnswer = {
        question: question.question,
        answer: question.answerOptions[chosenAnswer.charCodeAt(0)-65], 
        correctAnswer : answer.correctAnswer,
        readMore: answer.readMore,
        isCorrect: answer.answerIsCorrect
      } as Game.PreviousQuestion
      const questions = [newAnswer].concat(previousQuestions)
      setPreviousQuestions(questions)
      setQuestion(undefined)
      setChosenAnswer("");
      setCheckingAnswer(false)
    })
  },[chosenAnswer, checkingAnswer, previousQuestions, question, repository])

  const keyUp = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.repeat)
      return;
    if (question == null)
      return;

    if (event.key === 'A' || event.key === 'a')
      setChosenAnswer('A')
    if (event.key === 'B' || event.key === 'b')
      setChosenAnswer('B')
    if (event.key === 'C' || event.key === 'c')
      setChosenAnswer('C')
    if (event.key === 'D' || event.key === 'd')
      setChosenAnswer('D')

    if (chosenAnswer === "")
      return

    if (event.key === 'Enter') {
      checkAnswer()
    }
  }, [question, checkAnswer, chosenAnswer])

  useEffect(() => {
    const fetchData = async () => {
      const question = await repository.getRandomQuestion(0, 0)
      return question
    }
    if (question == null) {
      fetchData().then(question => {
        setQuestion(question)
      })
    }
  })

  if (question == null)
    return null

  const composerProps: Composer.Props = {
    question: question.question,
    answerOptions: question.answerOptions,
    chosenAnswer: chosenAnswer,
    answerQuestion : checkAnswer,
    setChosenAnswer: setChosenAnswer,
    previousQuestions: previousQuestions
  }
  return (
  <div tabIndex={17} onKeyUp={(e) => keyUp(e)}><Composer.Composer {...composerProps} /></div>)
}