import * as Domain from "../../../Domain";

export class Question implements Domain.Repository {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async getRandomQuestion(language: number, category: number): Promise<Domain.Question> {
    const fullUrl = `${this.apiUrl}/questions?language=${language}&category=${category}`;
    const response = await fetch(fullUrl)
    const json = await response.json()
    return json   
  }

  async checkAnswer(questionId: string, answer: string): Promise<Domain.Answer> {
    const fullUrl = `${this.apiUrl}/answers`
    const  body = {
      questionId : questionId,
      answer : answer
    }

    const response = await fetch(
      fullUrl, { 
      method: "Post", 
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body) })
    const json = await response.json();
    return json;
  }
}

